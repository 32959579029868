import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { theme } from 'app/theme'
import { AnimatePresence, motion } from 'framer-motion'
import React, { memo, ReactNode, useEffect, useState } from 'react'

interface Props {
  children: ReactNode
  location: any
}

export const Transition = memo(function Transition({
  children,
  location,
}: Props) {
  useEffect(() => {
    history.scrollRestoration = 'manual'
  }, [])

  return (
    <AnimatePresence mode="wait">
      <Main key={location.pathname}>
        <Cover
          initial={{ x: '-100%' }}
          animate={{ x: '-100%' }}
          exit={{ x: 0 }}
          transition={{ duration: 0.6, ease: [0.645, 0.045, 0.355, 1.0] }}
        />
        <Uncover
          initial={{ x: 0 }}
          animate={{ x: '100%' }}
          exit={{ x: '100%' }}
          transition={{
            duration: 0.6,
            ease: [0.645, 0.045, 0.355, 1.0],
            delay: 0.6,
          }}
        >
          <DecoWrapper>
            <AnimatedHeart
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 445.487 347.848"
              initial={{ strokeDashoffset: 1270 }}
              animate={{ strokeDashoffset: 0 }}
              exit={{ strokeDashoffset: 0 }}
              transition={{ duration: 0.5, ease: [0.645, 0.045, 0.355, 1.0] }}
            >
              <path
                fill="none"
                stroke="#c0002e"
                d="M125.115.707.707 125.115l222 222.026L444.78 125.115 320.414.749l-97.639 97.618Z"
                data-name="Tracciato 24"
              />
            </AnimatedHeart>
          </DecoWrapper>
        </Uncover>
        {children}
      </Main>
    </AnimatePresence>
  )
})

const Main = styled(motion.main)``

const Style = css`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  position: fixed;
  background: ${theme.colors.variants.secondaryLight};
`

const Cover = styled(motion.aside)`
  ${Style}
`

const Uncover = styled(motion.aside)`
  ${Style}
`

const DecoWrapper = styled.div`
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  position: absolute;

  svg {
    width: 20vw;
    height: auto;
  }
`
const AnimatedHeart = styled(motion.svg)`
  width: 20vw;
  height: auto;
  stroke-dasharray: 1270;
  stroke-dashoffset: 1270;

  @media (max-width: 991px) {
    width: 50vw !important;
    stroke-width: 3;
  }
`
